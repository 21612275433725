import React from 'react';

import Layout from '../../components/Layout';

import SEO from '../../components/SEO';

const Page = () => (
  <Layout>
    <SEO
      title="Privacy Policy"
      meta={[{ name: 'robots', content: 'noindex' }]}
    />
    <title>Privacy Policy</title>
    <h1>Privacy Policy for Facebook Apps</h1>
    <p>
      <strong>PRIVACY NOTICE</strong>
    </p>
    <p>
      <strong>Last updated May 01, 2022</strong>
    </p>
    <p>
      This privacy notice for River of Life Christian Fellowship ("
      <strong>Company</strong>," "<strong>we</strong>," "<strong>us</strong>,"
      or "<strong>our</strong>"), describes how and why we might collect, store,
      use, and/or share ("<strong>process</strong>") your information when you
      use our services ("<strong>Services</strong>"), such as when you:
    </p>
    <ul>
      <li>
        Download and use our Facebook application&nbsp;(RLCF Verses API), or any
        other application of ours that links to this privacy notice
      </li>
    </ul>
    <ul>
      <li>
        Engage with us in other related ways, including any sales, marketing, or
        events
      </li>
    </ul>
    <p>
      <strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice
      will help you understand your privacy rights and choices. If you do not
      agree with our policies and practices, please do not use our Services. If
      you still have any questions or concerns, please contact us at
      chetan.chadalavada@gmail.com.
    </p>
    <p>
      <strong>SUMMARY OF KEY POINTS</strong>
    </p>
    <p>
      <strong>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for. You can also click&nbsp;
      </strong>
      <a href="#toc">
        <strong>here</strong>
      </a>
      <strong>&nbsp;to go directly to our table of contents.</strong>
    </p>
    <p>
      <strong>What personal information do we process?</strong> When you visit,
      use, or navigate our Services, we may process personal information
      depending on how you interact with River of Life Christian Fellowship and
      the Services, the choices you make, and the products and features you use.
      Click&nbsp;
      <a href="#personalinfo">here</a>
      &nbsp;to learn more.
    </p>
    <p>
      <strong>Do we process any sensitive personal information?</strong> We do
      not process sensitive personal information.
    </p>
    <p>
      <strong>Do you receive any information from third parties?</strong> We do
      not receive any information from third parties.
    </p>
    <p>
      <strong>How do you process my information?</strong> We process your
      information to provide, improve, and administer our Services, communicate
      with you, for security and fraud prevention, and to comply with law. We
      may also process your information for other purposes with your consent. We
      process your information only when we have a valid legal reason to do so.
      Click&nbsp;
      <a href="#infouse">here</a>
      &nbsp;to learn more.
    </p>
    <p>
      <strong>
        In what situations and with which parties do we share personal
        information?
      </strong>{' '}
      We may share information in specific situations and with specific third
      parties. Click&nbsp;
      <a href="#whoshare">here</a>
      &nbsp;to learn more.
    </p>
    <p>
      <strong>How do we keep your information safe?</strong> We have
      organizational and technical processes and procedures in place to protect
      your personal information. However, no electronic transmission over the
      internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorized third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information.
      Click&nbsp;
      <a href="#infosafe">here</a>
      &nbsp;to learn more.
    </p>
    <p>
      <strong>What are your rights?</strong> Depending on where you are located
      geographically, the applicable privacy law may mean you have certain
      rights regarding your personal information. Click&nbsp;
      <a href="#privacyrights">here</a>
      &nbsp;to learn more.
    </p>
    <p>
      <strong>How do I exercise my rights?</strong> The easiest way to exercise
      your rights is by filling out our data subject request form available{' '}
      <a href="https://app.termly.io/notify/cfbf714f-0e7c-43ed-a50c-e04db65bd9c3">
        here
      </a>
      , or by contacting us. We will consider and act upon any request in
      accordance with applicable data protection laws.
    </p>
    <p>
      Want to learn more about what River of Life Christian Fellowship does with
      any information we collect? Click&nbsp;
      <a href="#toc">here</a>
      &nbsp;to review the notice in full.
    </p>
    <p>
      <strong>TABLE OF CONTENTS</strong>
    </p>
    <p>
      <a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a>
    </p>
    <p>
      <a href="#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a>
    </p>
    <p>
      <a href="#legalbases">
        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
      </a>
    </p>
    <p>
      <a href="#whoshare">
        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </a>
    </p>
    <p>
      <a href="#inforetain">5. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
    </p>
    <p>
      <a href="#infosafe">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
    </p>
    <p>
      <a href="#infominors">7. DO WE COLLECT INFORMATION FROM MINORS?</a>
    </p>
    <p>
      <a href="#privacyrights">8. WHAT ARE YOUR PRIVACY RIGHTS?</a>
    </p>
    <p>
      <a href="#DNT">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
    </p>
    <p>
      <a href="#caresidents">
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </a>
    </p>
    <p>
      <a href="#policyupdates">11. DO WE MAKE UPDATES TO THIS NOTICE?</a>
    </p>
    <p>
      <a href="#contact">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
    </p>
    <p>
      <a href="#request">
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </a>
    </p>
    <p>
      <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
    </p>
    <p>
      <strong>Personal information you disclose to us</strong>
    </p>
    <p>
      <strong>In Short:</strong>We collect personal information that you provide
      to us.
    </p>
    <p>
      We collect personal information that you voluntarily provide to us when
      you express an interest in obtaining information about us or our products
      and Services, when you participate in activities on the Services, or
      otherwise when you contact us.
    </p>
    <p>
      <strong>Sensitive Information.</strong> We do not process sensitive
      information.
    </p>
    <p>
      All personal information that you provide to us must be true, complete,
      and accurate, and you must notify us of any changes to such personal
      information.
    </p>
    <p>
      <strong>
        Information collected when you use our Facebook application(s).
      </strong>{' '}
      We by default access your&nbsp;
      <a href="https://www.facebook.com/about/privacy/">Facebook</a>&nbsp;basic
      account information, including your name, email, gender, birthday, current
      city, and profile picture URL, as well as other information that you
      choose to make public. We may also request access to other permissions
      related to your account, such as friends, check-ins, and likes, and you
      may choose to grant or deny us access to each individual permission. For
      more information regarding Facebook permissions, refer to the&nbsp;
      <a href="https://developers.facebook.com/docs/facebook-login/permissions">
        Facebook Permissions Reference
      </a>
      &nbsp;page.
    </p>
    <p>
      <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
    </p>
    <p>
      <strong>In Short:&nbsp;</strong>We process your information to provide,
      improve, and administer our Services, communicate with you, for security
      and fraud prevention, and to comply with law. We may also process your
      information for other purposes with your consent.
    </p>
    <p>
      <strong>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </strong>
    </p>
    <ul>
      <li>
        <strong>To save or protect an individual's vital interest.</strong> We
        may process your information when necessary to save or protect an
        individual’s vital interest, such as to prevent harm.
      </li>
    </ul>
    <p>
      <strong>
        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
      </strong>
    </p>
    <p>
      <strong>In Short:&nbsp;</strong>We only process your personal information
      when we believe it is necessary and we have a valid legal reason (i.e.,
      legal basis) to do so under applicable law, like with your consent, to
      comply with laws, to provide you with services to enter into or fulfill
      our contractual obligations, to protect your rights, or to fulfill our
      legitimate business interests.
    </p>
    <p>
      <strong>
        If you are located in the EU or UK, this section applies to you.
      </strong>
    </p>
    <p>
      The General Data Protection Regulation (GDPR) and UK GDPR require us to
      explain the valid legal bases we rely on in order to process your personal
      information. As such, we may rely on the following legal bases to process
      your personal information:
    </p>
    <ul>
      <li>
        <strong>Consent.&nbsp;</strong>We may process your information if you
        have given us permission (i.e., consent) to use your personal
        information for a specific purpose. You can withdraw your consent at any
        time. Click&nbsp;
        <a href="#withdrawconsent">here</a>
        &nbsp;to learn more.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Legal Obligations.</strong> We may process your information
        where we believe it is necessary for compliance with our legal
        obligations, such as to cooperate with a law enforcement body or
        regulatory agency, exercise or defend our legal rights, or disclose your
        information as evidence in litigation in which we are involved.
      </li>
    </ul>
    <ul>
      <li>
        <strong>Vital Interests.</strong> We may process your information where
        we believe it is necessary to protect your vital interests or the vital
        interests of a third party, such as situations involving potential
        threats to the safety of any person.
      </li>
    </ul>
    <p>
      <strong>
        If you are located in Canada, this section applies to you.
      </strong>
    </p>
    <p>
      We may process your information if you have given us specific permission
      (i.e., express consent) to use your personal information for a specific
      purpose, or in situations where your permission can be inferred (i.e.,
      implied consent). You can withdraw your consent at any time. Click&nbsp;
      <a href="#withdrawconsent">here</a>
      &nbsp;to learn more.
    </p>
    <p>
      In some exceptional cases, we may be legally permitted under applicable
      law to process your information without your consent, including, for
      example:
    </p>
    <ul>
      <li>
        If collection is clearly in the interests of an individual and consent
        cannot be obtained in a timely way
      </li>
    </ul>
    <ul>
      <li>For investigations and fraud detection and prevention</li>
    </ul>
    <ul>
      <li>For business transactions provided certain conditions are met</li>
    </ul>
    <ul>
      <li>
        If it is contained in a witness statement and the collection is
        necessary to assess, process, or settle an insurance claim
      </li>
    </ul>
    <ul>
      <li>
        For identifying injured, ill, or deceased persons and communicating with
        next of kin
      </li>
    </ul>
    <ul>
      <li>
        If we have reasonable grounds to believe an individual has been, is, or
        may be victim of financial abuse
      </li>
    </ul>
    <ul>
      <li>
        If it is reasonable to expect collection and use with consent would
        compromise the availability or the accuracy of the information and the
        collection is reasonable for purposes related to investigating a breach
        of an agreement or a contravention of the laws of Canada or a province
      </li>
    </ul>
    <ul>
      <li>
        If disclosure is required to comply with a subpoena, warrant, court
        order, or rules of the court relating to the production of records
      </li>
    </ul>
    <ul>
      <li>
        If it was produced by an individual in the course of their employment,
        business, or profession and the collection is consistent with the
        purposes for which the information was produced
      </li>
    </ul>
    <ul>
      <li>
        If the collection is solely for journalistic, artistic, or literary
        purposes
      </li>
    </ul>
    <ul>
      <li>
        If the information is publicly available and is specified by the
        regulations
      </li>
    </ul>
    <p>
      <strong>
        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </strong>
    </p>
    <p>
      <strong>In Short:</strong>&nbsp;We may share information in specific
      situations described in this section and/or with the following third
      parties.
    </p>
    <p>
      We may need to share your personal information in the following
      situations:
    </p>
    <ul>
      <li>
        <strong>Business Transfers.</strong> We may share or transfer your
        information in connection with, or during negotiations of, any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company.
      </li>
    </ul>
    <p>
      <strong>5. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>
    </p>
    <p>
      <strong>In Short:&nbsp;</strong>We keep your information for as long as
      necessary to fulfill the purposes outlined in this privacy notice unless
      otherwise required by law.
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary
      for the purposes set out in this privacy notice, unless a longer retention
      period is required or permitted by law (such as tax, accounting, or other
      legal requirements). No purpose in this notice will require us keeping
      your personal information for longer than __________.
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal
      information, we will either delete or anonymize such information, or, if
      this is not possible (for example, because your personal information has
      been stored in backup archives), then we will securely store your personal
      information and isolate it from any further processing until deletion is
      possible.
    </p>
    <p>
      <strong>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
    </p>
    <p>
      <strong>In Short:&nbsp;</strong>We aim to protect your personal
      information through a system of organizational and technical security
      measures.
    </p>
    <p>
      We have implemented appropriate and reasonable technical and
      organizational security measures designed to protect the security of any
      personal information we process. However, despite our safeguards and
      efforts to secure your information, no electronic transmission over the
      Internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorized third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information.
      Although we will do our best to protect your personal information,
      transmission of personal information to and from our Services is at your
      own risk. You should only access the Services within a secure environment.
    </p>
    <p>
      <strong>7. DO WE COLLECT INFORMATION FROM MINORS?</strong>
    </p>
    <p>
      <strong>In Short:</strong>&nbsp;We do not knowingly collect data from or
      market to children under 18 years of age.
    </p>
    <p>
      We do not knowingly solicit data from or market to children under 18 years
      of age. By using the Services, you represent that you are at least 18 or
      that you are the parent or guardian of such a minor and consent to such
      minor dependent’s use of the Services. If we learn that personal
      information from users less than 18 years of age has been collected, we
      will deactivate the account and take reasonable measures to promptly
      delete such data from our records. If you become aware of any data we may
      have collected from children under age 18, please contact us at
      chetan.chadalavada@gmail.com.
    </p>
    <p>
      <strong>8. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
    </p>
    <p>
      <strong>In Short:</strong>&nbsp;In some regions, such as the European
      Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that
      allow you greater access to and control over your personal
      information.&nbsp;You may review, change, or terminate your account at any
      time.
    </p>
    <p>
      In some regions (like the EEA, UK, and Canada), you have certain rights
      under applicable data protection laws. These may include the right (i) to
      request access and obtain a copy of your personal information, (ii) to
      request rectification or erasure; (iii) to restrict the processing of your
      personal information; and (iv) if applicable, to data portability. In
      certain circumstances, you may also have the right to object to the
      processing of your personal information. You can make such a request by
      contacting us by using the contact details provided in the section “
      <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>” below.
    </p>
    <p>
      We will consider and act upon any request in accordance with applicable
      data protection laws.
    </p>
    <p>
      If you are located in the EEA or UK and you believe we are unlawfully
      processing your personal information, you also have the right to complain
      to your local data protection supervisory authority. You can find their
      contact details here:{' '}
      <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
      </a>
      .
    </p>
    <p>
      If you are located in Switzerland, the contact details for the data
      protection authorities are available here:{' '}
      <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
        https://www.edoeb.admin.ch/edoeb/en/home.html
      </a>
      .
    </p>
    <p>
      <strong>Withdrawing your consent:</strong> If we are relying on your
      consent to process your personal information, which may be express and/or
      implied consent depending on the applicable law, you have the right to
      withdraw your consent at any time. You can withdraw your consent at any
      time by contacting us by using the contact details provided in the section
      "<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
    </p>
    <p>
      However, please note that this will not affect the lawfulness of the
      processing before its withdrawal, nor when applicable law allows, will it
      affect the processing of your personal information conducted in reliance
      on lawful processing grounds other than consent.
    </p>
    <p>
      If you have questions or comments about your privacy rights, you may email
      us at chetan.chadalavada@gmail.com.
    </p>
    <p>
      <strong>9. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
    </p>
    <p>
      Most web browsers and some mobile operating systems and mobile
      applications include a Do-Not-Track ("DNT") feature or setting you can
      activate to signal your privacy preference not to have data about your
      online browsing activities monitored and collected. At this stage no
      uniform technology standard for recognizing and implementing DNT signals
      has been finalized. As such, we do not currently respond to DNT browser
      signals or any other mechanism that automatically communicates your choice
      not to be tracked online. If a standard for online tracking is adopted
      that we must follow in the future, we will inform you about that practice
      in a revised version of this privacy notice.
    </p>
    <p>
      <strong>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</strong>
    </p>
    <p>
      <strong>In Short:&nbsp;</strong>Yes, if you are a resident of California,
      you are granted specific rights regarding access to your personal
      information.
    </p>
    <p>
      California Civil Code Section 1798.83, also known as the "Shine The Light"
      law, permits our users who are California residents to request and obtain
      from us, once a year and free of charge, information about categories of
      personal information (if any) we disclosed to third parties for direct
      marketing purposes and the names and addresses of all third parties with
      which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a
      request, please submit your request in writing to us using the contact
      information provided below.
    </p>
    <p>
      If you are under 18 years of age, reside in California, and have a
      registered account with Services, you have the right to request removal of
      unwanted data that you publicly post on the Services. To request removal
      of such data, please contact us using the contact information provided
      below and include the email address associated with your account and a
      statement that you reside in California. We will make sure the data is not
      publicly displayed on the Services, but please be aware that the data may
      not be completely or comprehensively removed from all our systems (e.g.,
      backups, etc.).
    </p>
    <p>
      <strong>11. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
    </p>
    <p>
      <strong>In Short:&nbsp;</strong>Yes, we will update this notice as
      necessary to stay compliant with relevant laws.
    </p>
    <p>
      We may update this privacy notice from time to time. The updated version
      will be indicated by an updated "Revised" date and the updated version
      will be effective as soon as it is accessible. If we make material changes
      to this privacy notice, we may notify you either by prominently posting a
      notice of such changes or by directly sending you a notification. We
      encourage you to review this privacy notice frequently to be informed of
      how we are protecting your information.
    </p>
    <p>
      <strong>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>
    </p>
    <p>
      If you have questions or comments about this notice, you may email us at
      chetan.chadalavada@gmail.com&nbsp;or by post to:
    </p>
    <p>River of Life Christian Fellowship</p>
    <p>1306 N Lincoln Ave</p>
    <p>Loveland, CO 80538</p>
    <p>United States</p>
    <p>
      <strong>
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </strong>
    </p>
    <p>
      Based on the applicable laws of your country, you may have the right to
      request access to the personal information we collect from you, change
      that information, or delete it in some circumstances. To request to
      review, update, or delete your personal information, please submit a
      request form by clicking&nbsp;
      <a href="https://app.termly.io/notify/cfbf714f-0e7c-43ed-a50c-e04db65bd9c3">
        here
      </a>
      .
    </p>
    <p>
      This privacy policy was created using Termly's{' '}
      <a href="https://termly.io/products/privacy-policy-generator">
        Privacy Policy Generator
      </a>
      .
    </p>
  </Layout>
);

export default Page;
